@import '../../styles/variables.scss';

.footer {
  height:100%;
  padding: 2rem;
  background: $color-secondary;
  border-top: 2px solid #000;

  &__wrap {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 0 3rem;
  }

  &__group {
    color: #fff;
    padding-bottom: 2rem;

    &:last-of-type {
      padding-bottom: 0;
    }

    &:nth-child(1) {
      flex-basis: 50%;
    }

    h4 {
      font-size: 1.5rem;
      padding-top: 0;
      margin: 0 0 1rem;
      color: #fff;
      font-weight: normal;
      opacity: .6;
    }

    a, span {
      font-size: 1.6rem;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      font-size: 1rem;

      li {
        padding-bottom: .5rem;

        span {
          display: inline-block;
          padding-right: 1rem;
        }
      }
    }

    a {
      color: #fff;
      line-height: 2rem;
      text-decoration: none;

      &:hover {
        color: #fff;
      }
    }
  }

  &__copyright {
    opacity: .5;
    color: #fff;
    font-size: 1rem;
    flex-basis: 70%;
    padding-top: 1rem;
    text-align: center;
    border-top: 1px solid #fff;
  }

  &__policies {
    list-style: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    align-items: flex-start;

    li {
      font-size: 1rem;

      a {
        display: inline-block;
        color: #fff;

        &:hover {
            color: #fff;
        }
      }
    }

    @media screen and (min-width: $tab) {
      justify-content: flex-end;
      flex-direction: row;

      li {
        padding: 0 0 0 1rem;
      }
    }
  }

  &__social {
    display: flex;
    list-style: none;
    padding: 0;

    li {
      padding-right: 2rem;
    }

    li:first-of-type a {
      padding-right: 0;
    }

    svg {
      width: 2rem;
      fill: #fff;
    }
  
    @media screen and (min-width: $tab) {
      position: relative;
      width: auto;
      border: none;
      padding: 0.3rem 0 0 0;
      bottom: 0;
    }
  }

  @media screen and (min-width: $tab) {
    padding: 3rem;

    &__wrap {
      justify-content: space-between;
      flex-direction: row;
    }

    &__copyright {
      text-align: right;
    }
  }
}