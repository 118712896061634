@font-face {
  font-family: 'ProximaNova-Light';
  src: url('../assets/fonts/proxima-nova-light.eot');
  src: url('../assets/fonts/proxima-nova-light.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/proxima-nova-light.woff2') format('woff2'), url('../assets/fonts/proxima-nova-light.woff') format('woff'), url('../assets/fonts/proxima-nova-light.ttf') format('truetype'), url('../assets/fonts/proxima-nova-light.svg#youworkforthem') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNova-Regular';
  src: url('../assets/fonts/proxima-nova-regular.eot');
  src: url('../assets/fonts/proxima-nova-regular.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/proxima-nova-regular.woff2') format('woff2'), url('../assets/fonts/proxima-nova-regular.woff') format('woff'), url('../assets/fonts/proxima-nova-regular.ttf') format('truetype'), url('../assets/fonts/proxima-nova-regular.svg#youworkforthem') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNova-Extrabold';
  src: url('../assets/fonts/proxima-nova-extrabold.eot');
  src: url('../assets/fonts/proxima-nova-extrabold.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/proxima-nova-extrabold.woff2') format('woff2'), url('../assets/fonts/proxima-nova-extrabold.woff') format('woff'), url('../assets/fonts/proxima-nova-extrabold.ttf') format('truetype'), url('../assets/fonts/proxima-nova-extrabold.svg#youworkforthem') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  line-height: 2.1rem;
  font-family: 'ProximaNova-Regular';
  letter-spacing: .1rem;
}

.h1 {
  font-size: 3.5rem;
  line-height: 3.6rem;
  text-transform: uppercase;
}

.h2 {
  font-size: 3.2rem;
  line-height: 3.1rem;
  text-transform: uppercase;
  margin-top: 0;
}

p {
  font-size: 1.6rem;
  margin: 0;

  &.justify {
    text-align: center;
  }
}

a {
  transition: opacity .3s;
  
  &:hover {
    opacity: .8;
  }
}