@import '../../styles/variables.scss';

.photogrid {
  $this: &;
  padding: 6rem 0 0rem;

  &--lightbox {
    padding-top: 0;

    .swiper-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    #{$this}__title {
      position: absolute;
      z-index: 2;
      color: #fff;
      width: 100%;
      font-size: 1.6rem;
      padding: .8rem 0;
      background: #000;
    }

    #{$this}__pagination {
      position: absolute;
      bottom: 2rem;
      z-index: 2;
      width: 100%;
    }
  }

  &__title {
    text-align: center;
    color: #111e32;
  }
  
  &__grid {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: space-between;
  }

  &__photo {
    $photo: &;
    background: #000;
    position: relative;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;

    &-img {
      transition: opacity .3s;
      width: 100%;
      max-height: 100%;
      display: flex;
      object-fit: contain;
    }

    &-overlay {
      transition: opacity .3s;
      font-family: 'ProximaNova-Light';
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      color: #fff;
    }

    &:hover {
      #{$photo}-overlay {
        opacity: 1;
      }
      #{$photo}-img {
        //opacity: .8;
      }
    }
  }

  &__desc {
    max-width: 100rem;
    padding: 0 2rem 2rem;
    color: #111e32;
    margin: 0 auto;
    text-align: center;

    #{$this}--lightbox & {
      display: none;
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    padding-top: 2rem;

    .swiper-pagination-bullet {
      display: block;
      width: .8rem;
      height: .8rem;
      background: $color-primary;
      margin: .3rem;

      &-active {
        background: $color-accent;
      }
    }
  }

  @media screen and (min-width: $tab) {
    padding: 9rem 0 0rem;

    &--lightbox {
      padding-top: 0;
    }
    
    &__grid {
      flex-wrap: wrap;
      flex-direction: row;
      overflow: hidden;
      justify-content: space-between;
    }

    &__desc {
      padding: 0 3rem 2rem;
    }

    &__photo {
      width: 50%;
    }
  }

  @media screen and (min-width: $tabDesk) {
    &__photo {
      width: 33.333333%;
    }
  }
}
