@import '../../styles/variables.scss';

.cta {
  transition: all .3s;
  font-family: 'ProximaNova-Regular';
  font-size: 2rem;
  display: inline-block;
  border-radius: 2rem;
  background: $color-secondary;
  border: none;
  padding: 1rem 3rem;
  color: #fff;
  cursor: pointer;

  &:hover {
    background: $color-accent;
  }

  &:active {
    
  }
}