@import '../../styles/variables.scss';

.social {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;

  li + li {
    padding-left: 1.5rem;
  }

  svg {
    width: 1.8rem;
    fill: #fff;
  }

  &__instagram {
    margin-top: 1px;
  }

  @media screen and (min-width: $tab) {
    position: relative;
    width: auto;
    border: none;
    bottom: 0;
  }
}