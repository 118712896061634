html {
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
}

.layout {
  margin: 0 auto;
  //max-width: 140rem;

  &--slim {
    max-width: 80rem;
  }
}