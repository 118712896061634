@import '../../styles/variables.scss';

.home {
  color: #fff;

  &__intro {
    background: #334f67;
    height: calc(100vh - 10rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: .5rem solid $color-secondary;
  }

  &__title {
    display: flex;
    justify-content: center;
    
    padding: 0 3rem;
    margin-bottom: 3rem;
    animation-name: logo;
    animation-duration: 2s;
    animation-fill-mode: forwards;

    img {
      width: 100%;
      max-width: 60rem;
      align-self: center;
    }
  }

  &__copy {
    background: $color-secondary;
    padding: 3rem;

    p {
      margin: 0 auto;
      max-width: 80rem;
      padding: 0;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    margin: 1rem 0 0;
    padding: 0;
    text-align: center;

    li + li {
      padding-top: .5rem;
    }
  }

  &__cta {
    opacity: 0;
    animation-name: fadein;
    animation-duration: 1.5s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }

  &__portfolio {
    padding: 0rem 0rem 6rem;
    border-top: .3rem solid #0b1523;
  }

  &__awards, &__map {
    border-top: .3rem solid rgba(#0b1523, .1);
    padding: 6rem 2rem 3rem;
  }

  &__map {
    text-align: center;

    &-title {
      text-align: center;
      color: $color-secondary;
    }

    &-desc {
      max-width: 85rem;
      margin: 0 auto;
      color: $color-secondary;
    }
  }

  &__lightbox {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;

    iframe {
      width: 100%;
      height: 100%;
    }

    svg {
      z-index: 2;
    }
  }

  @media screen and (min-width: $tab) {
    &__portfolio {
      padding: 0 0rem 12rem;
    }

    &__awards {
      padding: 6rem 3rem;
    }
  }
}

@keyframes logo {
  0% {
    opacity: 0;
    transform: translateY(0%) scale(.9);
  }
  75% {
    transform: translateY(-5%) scale(1);
  }
  100% {
    opacity: 1;
    transform: translateY(-5%);
  }
}

@keyframes copy {
  from {
    opacity: 0;
    transform: translateY(-5%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}