@import '../../styles/variables.scss';

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  z-index: 99;
  opacity: 0;
  transform: scale(.9);
  animation-name: lightboxFadein;
  animation-duration: .4s;
  animation-fill-mode: forwards;

  &__close {
    position: absolute;
    right: 0rem;
    top: 0rem;
    background: transparent;
    border: none;
    padding: 2.4rem;
    z-index: 3;
    cursor: pointer;

    &:after, &:before {
      content: '';
      position: absolute;
      height: 2.5rem;
      width: .3rem;
      background: #fff;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:hover {
      &:after, &:before {
        background: $color-accent;
      }
    }
  }
}

@keyframes lightboxFadein {
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
