@import '../../styles/variables.scss';

.header {
  $header: &;
  transition: all .3s;
  position: fixed;
  background: transparent;
  margin: 0 auto;
  padding: 1rem;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;

  &--bg {
    background: $color-primary;
    box-shadow: 0 0px 1rem rgba($color-secondary,.5);

    #{$header}__logo {
      opacity: 1;
    }
  }

  &--hide {
    transform: translateY(-100%);
  }

  &__logo {
    transition: opacity .3s;
    opacity: 0;
    
    img {
      width: 100%;
      max-width: 6rem;
      display: flex;
    }
  }

  &__nav {
    position: absolute;
    right: 0;
    top: 0;
  }

  &__link {
    color: #fff;
    font-size: 3.8rem;
    text-transform: uppercase;
    text-decoration: none;
    display: block;
  }

  &__menuToggle {
    display: block;
    position: relative;
    top: 2.7rem;
    right: 2rem;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;

    @media screen and (min-width: $tab) {
      display: flex;
    }

    @media screen and (min-width: $tabDesk) {
      top: 3rem;
      right: 3rem;
    }

    input {
      display: block;
      width: 4rem;
      height: 3.2rem;
      position: absolute;
      top: -.7rem;
      left: -.5rem;
      cursor: pointer;
      opacity: 0; /* hide this */
      z-index: 2; /* and place it over the hamburger */
      -webkit-touch-callout: none;

      &:focus ~ span {
        background: $color-accent;
      }
    }

    span {
      display: block;
      width: 3.2rem;
      height: .4rem;
      margin-bottom: .5rem;
      position: relative;
      background: #fff;
      border-radius: .3rem;
      z-index: 1;
      transform-origin: .4rem 0px;
      transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                  background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                  opacity 0.55s ease;
    }

    span:first-child {
      transform-origin: 0% 0%;
    }

    span:nth-last-child(2) {
      transform-origin: 0% 100%;
    }

    input:checked ~ span {
      opacity: 1;
      transform: rotate(45deg) translate(-2px, -1px);
    }

    input:checked ~ span:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    input:checked ~ span:nth-last-child(2) {
      transform: rotate(-45deg) translate(1px, -1px);
    }

    input:checked ~ ul {
      transform: none;
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    text-align: center;
    margin: 0;
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    background: $color-secondary;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(100%, 0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

    li {
      padding: 0 0 4rem;
      display: flex;
      justify-content: center;
    }
  }

  &__social {
    position: absolute;
    bottom: 0rem;
    width: calc(100% - 4rem);;
  }

  @media screen and (min-width: $tab) {
    display: flex;
    justify-content: space-between;

    input, span {
      display: none;
    }

    &__link {
      font-size: 1.6rem;
    }

    &__menu {
      position: relative;
      flex-direction: row;
      margin: 0;
      padding: 0;
      list-style: none;
      transform: none;
      height: initial;
      background: transparent;

      > li {
        padding: 0 0 0 2rem;
      }
    }

    &__social {
      position: relative;
      bottom: initial;
    }
  }

  @media screen and (min-width: $tabDesk) {
    padding: 1rem 2rem;
    width: calc(100% - 4rem);
  }
}
